import React from 'react'
import Helmet from 'react-helmet'
import styles from './post-a-job.module.css'
import Layout from '../components/layout'

import wallet from '../images/wallet.png'
import resume from '../images/resume.png'
import email from '../images/email.png'

const PostAJobPage = () => (
  <Layout>
    <Helmet
      title={`Post a Job - Remote Job Clubs: Work from Anywhere`}
    />
    <div className={styles.root}>
      <h1>Hire remotely <span aria-label="handshake" role="img"> 👩‍💼 </span></h1>
      <p>Find top talent with Remote Jobs Club</p>

      <div className={styles.features}>
        <div className={styles.feature}>
          <img alt="email" src={email} />
          <p>Your job post is emailed to 3,500+ remote workers</p>
        </div>
        <div className={styles.feature}>
          <img alt="resume" src={resume} />
          <p>Expect 150+ job applicants</p>
        </div>
        <div className={styles.feature}>
          <img alt="cost of ad" src={wallet} />
          <p>Only for one-time payment of $150</p>
        </div>
      </div>
      <p className={styles.contact}>Ready to get started? <a href="mailto:sanjeevan@remotejobsclub.com">Contact me</a></p>

      <div className={styles.faq}>
        <h2>FAQ</h2>
        <div className={styles.faqlist}>
          <div className={styles.qa}>
            <h3>When will my job post will be sent out?</h3>
            <p>Our bi-weekly newsletter goes out every Monday, 9 am central.</p>
          </div>
          <div className={styles.qa}>
            <h3>Does the job have to be remote?</h3>
            <p>Yes. No exceptions. Sorry!</p>
          </div>
          <div className={styles.qa}>
            <h3>Will I get the analytics?</h3>
            <p>We track all the clicks to the job post, and I'm happy to send you the analytics for that.</p>
          </div>
          <div className={styles.qa}>
            <h3>I have other questions..</h3>
            <p>Feel free to email me at sanjeevan@remotejobsclub.com</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default PostAJobPage
